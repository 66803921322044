import Image from "next/image";
import React from "react";

import { cleanImage } from "@/services/imageHandling";
const Banner = ({ homebanner }) => {
  return (
    <>
      <div
        id="carouselExampleDark"
        className="carousel carousel-dark slide"
        data-bs-ride="carousel"
        data-bs-interval="6000"
      >
        <div className="carousel-inner ">
          <div className="carousel-item active">
            <div class="background-video-wrapper d-none d-md-block" >
              <video id="homeVideo" autoPlay
                className="background-video"
                loop="loop"
                muted
                preload="none" src="/images/bannervideo.mp4"></video></div>
            <div class="background-video-wrapper d-block d-md-none" >
              <video id="homeVideo" autoPlay
                className="background-video"
                loop="loop"
                muted
                preload="none" src="/images/mobilevideo.mp4"></video></div>
          </div>
          {
            homebanner.map((homeitem, i) => {
              return (
                <div className={`carousel-item `} key={`homebanner_${i}`}>
                  <Image
                    src={cleanImage(homeitem?.DesktopBanner?.data?.attributes?.url)}
                    className="d-none d-md-block  w-100"
                    alt="banner"
                    width={1920}
                    height={828}
                    style={{ height: "auto" }}
                  />
                  <Image
                    src={cleanImage(homeitem?.MobileBanner?.data?.attributes?.url)}
                    className="d-block d-md-none  w-100"
                    alt="banner"
                    width={850}
                    height={828}
                    style={{ height: "auto" }}
                  />
                </div>
              )
            })
          }
          {/* <div className="carousel-item active">
            <Image
              src={sliderWeb2}
              className="d-none d-md-block  w-100"
              alt="banner"
              style={{ width: "auto", height: "auto" }}
            />
            <Image
              src={sliderMob2}
              className="d-block d-md-none  w-100"
              alt="banner"
              style={{ width: "auto", height: "auto" }}
            />
          </div>
          <div className="carousel-item">
            <Image
              src={sliderWeb5}
              className="d-none d-md-block  w-100"
              alt="banner"
              style={{ width: "auto", height: "auto" }}
            />
            <Image
              src={sliderMob5}
              className="d-block d-md-none  w-100"
              alt="banner"
              style={{ width: "auto", height: "auto" }}
            />
          </div>
          <div className="carousel-item">
            <Image
              src={sliderWeb6}
              className="d-none d-md-block  w-100"
              alt="banner"
              style={{ width: "auto", height: "auto" }}
            />
            <Image
              src={sliderMob6}
              className="d-block d-md-none  w-100"
              alt="banner"
              style={{ width: "auto", height: "auto" }}
            />
          </div>
          <div className="carousel-item">
            <Image
              src={sliderWeb7}
              className="d-none d-md-block  w-100"
              alt="banner"
              style={{ width: "auto", height: "auto" }}
            />
            <Image
              src={sliderMob7}
              className="d-block d-md-none  w-100"
              alt="banner"
              style={{ width: "auto", height: "auto" }}
            />
          </div> */}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
};

export default Banner;
