import React from "react";
import Banner from "../components/Home/Banner";
import Image from "next/image";
import projlogo from "../public/images/newkolkata/new_kolkata_logo.png";
import homeparallax from "../public/images/newkolkata/newkolkata-001.jpg";
import homeparallaxmob from "../public/images/newkolkata/newkolkata-001-mob.jpg";
import clubhouse from "../public/images/newkolkata/newkolkata-002.jpg";
import clubhousemob from "../public/images/newkolkata/newkolkata-002-mob.jpg";
import gym from "../public/images/newkolkata/newkolkata-003.jpg";
import gymmob from "../public/images/newkolkata/newkolkata-003-mob.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import { Col, Container, Row, Tabs, Tab } from "react-bootstrap";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Link from "next/link";
import Testimonial from "@/components/Testimonial";
import Partners from "@/components/Partners";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import qs from "qs";
import Seo from "@/components/seo/Seo";
import { wrapper } from "@/redux/store";
import { getConstructionUpdate } from "@/redux/actions/constructionUpdateAction";
import { useSelector } from "react-redux";
import { cleanImage } from "@/services/imageHandling";

export default function Home({ data }) {
  Fancybox.bind("[data-fancybox]", {
    // Your custom options
    Hash: false,
  });
  const seo = data?.attributes?.seo;
  const homebanner = data?.attributes?.Banner;
  const { constructionUpdate } = useSelector(
    (state) => state.constructionUpdate
  );
  let constructionImages =
    constructionUpdate[0]?.attributes?.Construction_Images;
  let constructionID = constructionUpdate[0]?.id;
  // console.log('constructionUpdate',constructionUpdate)
  return (
    <>
      <Seo seo={seo} />

      <Banner homebanner={homebanner} />
      {/* <Enquiryform /> */}
      <section className="sec-padd amenities blue_bg">
        <Container>
          <div className="head_wrap text-center">
            <div className="center-title">
              <h2
                className="section-title text-white mb-0 "
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="1000"
              >
                <span>A NEW CITY IS WAITING FOR YOU</span>
              </h2>
            </div>
          </div>
          <div className="mt-60">
            <Swiper
              modules={[Autoplay, Navigation]}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              navigation={{
                nextEl: ".cust-swiper-button-next",
                prevEl: ".cust-swiper-button-prev",
              }}
              speed={1200}
              loop={true}
              className="cust_slider"
              slidesPerView={2}
              breakpoints={{
                767: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                1200: {
                  slidesPerView: 7,
                  spaceBetween: 15,
                },
              }}
            >
              <SwiperSlide>
                <div
                  className="icon_box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  <div className="img-wrap image-container">
                    <Image
                      fill
                      src="/images/icons/Icon_5.png"
                      alt="interior"
                      className="image"
                    />
                  </div>
                  <p className="my-3">Tallest in Hooghly (Floors- G+2P+28)</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="icon_box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  data-aos-duration="1000"
                >
                  <div className="img-wrap image-container">
                    <Image
                      fill
                      src="/images/icons/Icon_10.png"
                      alt="interior"
                      className="image"
                    />
                  </div>
                  <p className="my-3">1, 2, 3 & 4 BHK Apartments</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="icon_box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                >
                  <div className="img-wrap image-container">
                    <Image
                      fill
                      src="/images/icons/Icon_6.png"
                      alt="interior"
                      className="image"
                    />
                  </div>
                  <p className="my-3">Starting From ` 43,70,000</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="icon_box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-duration="1000"
                >
                  <div className="img-wrap image-container">
                    <Image
                      fill
                      src="/images/icons/Icon_7.png"
                      alt="interior"
                      className="image"
                    />
                  </div>
                  <p className="my-3">Biggest Clubhouse</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="icon_box"
                  data-aos="fade-up"
                  data-aos-delay="500"
                  data-aos-duration="1000"
                >
                  <div className="img-wrap image-container">
                    <Image
                      fill
                      src="/images/icons/Icon_11.png"
                      alt="interior"
                      className="image"
                    />
                  </div>
                  <p className="my-3">Private Jetty & Ferry Service</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="icon_box"
                  data-aos="fade-up"
                  data-aos-delay="600"
                  data-aos-duration="1000"
                >
                  <div className="img-wrap image-container">
                    <Image
                      fill
                      src="/images/icons/Icon_8.png"
                      alt="interior"
                      className="image"
                    />
                  </div>
                  <p className="my-3">Fire Safety</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="icon_box"
                  data-aos="fade-up"
                  data-aos-delay="700"
                  data-aos-duration="1000"
                >
                  <div className="img-wrap image-container">
                    <Image
                      fill
                      src="/images/icons/Icon_9.png"
                      alt="interior"
                      className="image"
                    />
                  </div>
                  <p className="my-3">Earthquake Safety</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </Container>
      </section>
      <section className="sec-padd">
        <Container>
          <div className="stay-connect-text home-stay-connect-text text-center">
            <p>
              Looking for a pocket friendly residence but worried about quality,
              hidden charges and complicated buying processes? <br />
              <br />
              Buy your forever home in <b>Alcove New Kolkata</b>,<br />a
              Township by Alcove Realty,
              <br />
              <b>at Serampore, Hooghly!</b>
              <br />
              <b>We’re the makers of:</b>
              <br />- THE 42
              <br />- Alcove Gloria
              <br />- Alcove Regency
              <br />- Brindavan Gardens
              <br />
              &amp; many more iconic residences and mixed use spaces in Kolkata.
              <br />
              Explore our township on the banks of Ganga; a first of its kind
              project in India.
            </p>
            <br />
            <p>
              <a className="btn" href="/sangam-residential-flat">
                See It, Believe It!
              </a>
            </p>
            <br />
            <b>Find The Right Home For You And Your Loved Ones!</b>
            <br />- Value for Money
            <br />- 65+ Modern Lifestyle Benefits
            <br />- Serene Environment With Urban Comfort
            <br />
            <br />
            <p>
              <a className="btn" href="/sangam-residential-flat">
                Explore My Options
              </a>
            </p>
          </div>
        </Container>
      </section>
      <section className="newkolkata-section">
        <div className="container">
          <div className="project-logo">
            <div className="img-wrap">
              <Image alt="" className="= new-kolkata-logo" src={projlogo} />
            </div>
          </div>
          <div className="project-img-box project01">
            <div
              className="parallax parallax-left hideme scale-box"
              style={{
                backgroundImage: `url(${homeparallax.src})`,
                backgroundRepeat: "no-repeat",
                height: "456px",
              }}
            >
              <div className="img-wrap image-container">
                <Image
                  fluid="true"
                  alt=""
                  className="= mob_banners_home image"
                  src={homeparallaxmob}
                />
              </div>
            </div>
          </div>
          <div className="project-img-box project02 bg-transparent">
            <div
              className="parallax parallax-left hideme scale-box"
              style={{
                backgroundImage: `url(${clubhouse.src})`,
                backgroundRepeat: "no-repeat",
                transform: "scale(1.28)",
                height: "456px",
              }}
            >
              <div className="img-wrap image-container">
                <Image
                  fluid="true"
                  alt=""
                  className="image mob_banners_home image"
                  src={clubhousemob}
                />
              </div>
            </div>
          </div>
          <div className="project-img-box project03">
            <div
              className="parallax parallax-left hideme scale-box"
              style={{
                backgroundImage: `url(${gym.src})`,
                backgroundRepeat: "no-repeat",
                transform: "scale(1.28)",
                height: "456px",
              }}
            >
              <div className="img-wrap image-container">
                <Image
                  fluid="true"
                  alt=""
                  className="image mob_banners_home image"
                  src={gymmob}
                />
              </div>
            </div>
          </div>
          <div className="project-img-box project-text-box">
            <div className="project-inner-textbox">
              <h3 className="heading03">SANGAM</h3>
              <p className="txt">
                Alcove Realty’s Exclusive Project Where Everyone Can Enjoy A
                Slice Of Heaven!
              </p>
              <a
                className="btn "
                href="/sangam-residential-flat"
                target="_blank"
              >
                <span>Show Me The Plans</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="sec-padd1">
        <Container>
          <div
            className="head_wrap text-center"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
          >
            <div className="center-title">
              <h2 className="section-title mb-0">
                <span>Stay Connected</span>
              </h2>
              <p>
                Ease Of Connectivity Through Road, Rail And River To Sangam
                Township!
              </p>
            </div>
          </div>
          <ul className="d-flex align-items-center flex-column mt-4">
            <li>Find The Main Entry Gate On G.T. Road</li>
            <li>1.9km Rishra and 2.6km Serampore Station</li>
            <li>
              Reach Dakshineshwar Metro Through The Private Jetty With Ferry
              Service
            </li>
          </ul>
          <div className="mt-30">
            <Row>
              <Col md={6} sm={12} xs={12}>
                <div className="img-wrap image-container gall_wrp p-0 pe-md-4">
                  <div
                    data-fancybox="map"
                    data-src="/images/new_map.jpg"
                    className="img_overlay"
                  >
                    <Image
                      fill
                      src="/images/new_map.jpg"
                      alt="interior"
                      className="image"
                    />
                    <div className="gall_over"></div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="location_wrapper">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Health</Accordion.Header>
                      <Accordion.Body>
                        <ul className="loc_list list-unstyled">
                          <li>New Kolkata Sangam to Walsh Hospital - 2.3 KM</li>
                          <li>
                            New Kolkata Sangam to ESIHospital distance - 3.2 KM
                          </li>
                          <li>
                            New Kolkata Sangam to Care Concern Hospital Pvt Ltd
                            - 2.8 KM
                          </li>
                          <li>
                            New Kolkata Sangam to Mani Kamal Hospital - 5.3 KM
                          </li>
                          <li>
                            New Kolkata Sangam to Disha Eye Hospital Sheoraphuli
                            - 5.5 KM
                          </li>
                          <li>
                            New Kolkata Sangam to RE-LIFE HOSPITAL - 6.2 KM
                          </li>
                          <li>
                            New Kolkata Sangam to Paramount Hospital - 900 MTR
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Educational Institution's
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="loc_list list-unstyled">
                          <li>
                            New Kolkata Sangam to Mahesh Sri Ramakrishna Ashram
                            - 1.2 KM
                          </li>
                          <li>
                            New Kolkata Sangam to Holy Home School - 2.2 KM .
                          </li>
                          <li>
                            New Kolkata Sangam to West Point Academy - 700 MTR
                          </li>
                          <li>
                            New Kolkata Sangam to Narayana School - 2.6 KM
                          </li>
                          <li>
                            New Kolkata Sangam to Aditya Birla Vani Bharati
                            School - 2.5 KM
                          </li>
                          <li>
                            New Kolkata Sangam to Serampore College - 2.1 KM
                          </li>
                          <li>
                            New Kolkata Sangam to Government College of
                            Engineering & Textile Technology - 2 KM
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Bank</Accordion.Header>
                      <Accordion.Body>
                        <ul className="loc_list list-unstyled">
                          <li>State Bank of India - 180 MTR. </li>
                          <li>Punjab National Bank - 1 KM </li>
                          <li>Axis Bank - 2.4 KM </li>
                          <li>HDFC Bank - 1.7 KM </li>
                          <li>ICICI Bank - 2.5 KM </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        Serampore Court, Serampore
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="loc_list list-unstyled">
                          <li>Serampore Court - 2.7 KM </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Retail</Accordion.Header>
                      <Accordion.Body>
                        <ul className="loc_list list-unstyled">
                          <li>Spencer's - 51 MTR. </li>
                          <li>More - 1.5 KM </li>
                          <li>Pantaloons - 1.6 KM </li>
                          <li>Reliance Digital - 700 MTR. </li>
                          <li>Trends - 900 MTR. </li>
                          <li>Max - 900 MTR. </li>
                          <li>Great Eastern Trading Co - 500 MTR. </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Entertainment and Dining
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="loc_list list-unstyled">
                          <li>
                            Goodwin Cineplex, Manasi Bazar, Serampore - 2.6 KM
                          </li>
                          <li>Udayan Cinema, Sheoraphuli - 6 KM </li>
                          <li>Aminia - 550 MTR. </li>
                          <li>KFC - 850 MTR. </li>
                          <li>Chowman - 550 MTR. </li>
                          <li>The Denmark Tavern - 2.7 KM </li>
                          <li>Mukherjee's Restaurant - 1.6 KM </li>
                          <li>Domino's - 1.2 KM </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  {/* <div className="map-box">
                    <div className="img-wrap image-container">
                      <Image
                        fill
                        src="/images/icons/Icon_2.png"
                        alt="interior"
                        className="image"
                      />
                    </div>
                    <div className="add_wrap">
                      <h4 className="loc_title">
                        <strong>Rails</strong>
                      </h4>
                      <ul className="accord_list list-unstyled mt-2">
                        <li>Rishra Railway Station - 1.8kms </li>
                        <li>Serampore Railway Station - 2.6kms </li>
                        <li>Howrah Station - 18.9kms </li>
                        <li>Sealdah Station - 23kms</li>
                        <li>flush door</li>
                      </ul>
                    </div>
                  </div>
                  <div className="map-box">
                    <div className="img-wrap image-container">
                      <Image
                        fill
                        src="/images/icons/Icon_3.png"
                        alt="interior"
                        className="image"
                      />
                    </div>
                    <div className="add_wrap">
                      <h4 className="loc_title">
                        <strong>Metro</strong>
                      </h4>
                      <ul className="accord_list list-unstyled mt-2">
                        <li>Dakshineshwar Metro - 13.9kms</li>
                      </ul>
                    </div>
                  </div>
                  <div className="map-box">
                    <div className="img-wrap image-container">
                      <Image
                        fill
                        src="/images/icons/Icon_1.png"
                        alt="interior"
                        className="image"
                      />
                    </div>
                    <div className="add_wrap">
                      <h4 className="loc_title">
                        <strong>Air</strong>
                      </h4>
                      <ul className="accord_list list-unstyled mt-2">
                        <li>Airport - 21.4kms</li>
                      </ul>
                    </div>
                  </div>
                  <div className="map-box">
                    <div className="img-wrap image-container">
                      <Image
                        fill
                        src="/images/icons/Icon_4.png"
                        alt="interior"
                        className="image"
                      />
                    </div>
                    <div className="add_wrap">
                      <h4 className="loc_title">
                        <strong>Road</strong>
                      </h4>
                      <ul className="accord_list list-unstyled mt-2">
                        <li>New Town - 45 mins</li>
                        <li>Esplanade - 22.1kms</li>
                        <li>Sector V - 27.3kms</li>
                        <li>Dakshineshwar - 11.8kms</li>
                      </ul>
                    </div>
                  </div> */}
                  <a
                    className="read-more round-btn"
                    href="https://www.google.co.in/maps/place/NEW+KOLKATA+-+Alcove+Developers+LLP/@22.7378016,88.3542331,17z/data=!3m1!4b1!4m5!3m4!1s0x39f89b1ea939a2f5:0xf04353dc5898ef13!8m2!3d22.7378016!4d88.3564218"
                    target="_blank"
                  >
                    View on google map
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="sec-padd2 gallery grey_bg pt-0">
        <Container>
          <div
            className="head_wrap text-center"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
          >
            <div className="center-title">
              <h2 className="section-title mb-0">
                <span>Types of Apartments New Kolkata Offers</span>
              </h2>
            </div>
          </div>
          <div className="mt-60">
            <Tabs
              defaultActiveKey="sangam"
              id="gallery"
              className="mb-3 justify-content-center"
              variant="pills"
            >
              <Tab eventKey="sangam" title="Sangam">
                <Swiper
                  modules={[Autoplay, Navigation]}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  navigation={{
                    nextEl: ".cust-swiper-button-next",
                    prevEl: ".cust-swiper-button-prev",
                  }}
                  loop={true}
                  speed={1200}
                  className="cust_slider"
                  breakpoints={{
                    767: {
                      slidesPerView: 1,
                      spaceBetween: 24,
                    },
                    992: {
                      slidesPerView: 2,
                      spaceBetween: 24,
                    },
                    1200: {
                      slidesPerView: 3,
                      spaceBetween: 24,
                    },
                  }}
                >
                  <SwiperSlide>
                    <div className="img-wrap image-container gall_wrp">
                      <div
                        data-fancybox="sangam"
                        data-src="/images/floorplan/sangam/sangam_signature_2BHK_FG.jpg"
                        className="img_overlay"
                      >
                        <Image
                          fill
                          src="/images/floorplan/sangam/sangam_signature_2BHK_FG.jpg"
                          alt="interior"
                          className="image"
                        />
                        <div className="gall_over"></div>
                      </div>
                    </div>
                    <div className="gall_detail text-center">
                      <p className="gal_txt gal_title mb-0">Sangam Signature</p>
                      <p className="my-1 gal_conf">
                        <strong> 2 BHK - FG</strong>
                      </p>
                      <p className="mb-0 gal_price">
                        887 Sq.ft{" "}
                        <i className="fa-solid fa-indian-rupee-sign"></i> 43.70
                        Lakh Onwards
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img-wrap image-container gall_wrp">
                      <div
                        data-fancybox="sangam"
                        data-src="/images/floorplan/sangam/sangam_signature_2BHK_EH.jpg"
                        className="img_overlay"
                      >
                        <Image
                          fill
                          src="/images/floorplan/sangam/sangam_signature_2BHK_EH.jpg"
                          alt="interior"
                          className="image"
                        />
                        <div className="gall_over"></div>
                      </div>
                    </div>
                    <div className="gall_detail text-center">
                      <p className="gal_txt gal_title mb-0">Sangam Signature</p>
                      <p className="my-1 gal_conf">
                        <strong> 2 BHK - EH</strong>
                      </p>
                      <p className="mb-0 gal_price">
                        910 Sq.ft{" "}
                        <i className="fa-solid fa-indian-rupee-sign"></i> 43.70
                        Lakh Onwards
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img-wrap image-container gall_wrp">
                      <div
                        data-fancybox="sangam"
                        data-src="/images/floorplan/sangam/sangam_signature_3BHK.jpg"
                        className="img_overlay"
                      >
                        <Image
                          fill
                          src="/images/floorplan/sangam/sangam_signature_3BHK.jpg"
                          alt="interior"
                          className="image"
                        />
                        <div className="gall_over"></div>
                      </div>
                    </div>
                    <div className="gall_detail text-center">
                      <p className="gal_txt gal_title mb-0">Sangam Signature</p>
                      <p className="my-1 gal_conf">
                        <strong> 3 BHK</strong>
                      </p>
                      <p className="mb-0 gal_price">
                        1039 Sq.ft{" "}
                        <i className="fa-solid fa-indian-rupee-sign"></i> 50.50
                        Lakh Onwards
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img-wrap image-container gall_wrp">
                      <div
                        data-fancybox="sangam"
                        data-src="/images/floorplan/sangam/sangam_suits_3BHK.jpg"
                        className="img_overlay"
                      >
                        <Image
                          fill
                          src="/images/floorplan/sangam/sangam_suits_3BHK.jpg"
                          alt="interior"
                          className="image"
                        />
                        <div className="gall_over"></div>
                      </div>
                    </div>
                    <div className="gall_detail text-center">
                      <p className="gal_txt gal_title mb-0">Sangam Suite </p>
                      <p className="my-1 gal_conf">
                        <strong> 3 BHK</strong>
                      </p>
                      <p className="mb-0 gal_price">
                        1400 Sq.ft{" "}
                        <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                        61,60,000 Onwards
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img-wrap image-container gall_wrp">
                      <div
                        data-fancybox="sangam"
                        data-src="/images/floorplan/sangam/4BHK_UNIT_PLAN_New.jpg"
                        className="img_overlay"
                      >
                        <Image
                          fill
                          src="/images/floorplan/sangam/4BHK_UNIT_PLAN_New.jpg"
                          alt="interior"
                          className="image"
                        />
                        <div className="gall_over"></div>
                      </div>
                    </div>
                    <div className="gall_detail text-center">
                      <p className="gal_txt gal_title mb-0">Sangam Suite </p>
                      <p className="my-1 gal_conf">
                        <strong> 4 BHK</strong>
                      </p>
                      <p className="mb-0 gal_price">
                        2085 Sq.ft{" "}
                        <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                        92,89,000 Onwards
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img-wrap image-container gall_wrp">
                      <div
                        data-fancybox="sangam"
                        data-src="/images/floorplan/sangam/sangam_sernity_1BHK_A.jpg"
                        className="img_overlay"
                      >
                        <Image
                          fill
                          src="/images/floorplan/sangam/sangam_sernity_1BHK_A.jpg"
                          alt="interior"
                          className="image"
                        />
                        <div className="gall_over"></div>
                      </div>
                    </div>
                    <div className="gall_detail text-center">
                      <p className="gal_txt gal_title mb-0">Sangam Serenity </p>
                      <p className="my-1 gal_conf">
                        <strong> 1 BHK Type - A</strong>
                      </p>
                      <p className="mb-0 gal_price">
                        556 Sq.ft{" "}
                        <i className="fa-solid fa-indian-rupee-sign"></i> Price
                        On Request
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img-wrap image-container gall_wrp">
                      <div
                        data-fancybox="sangam"
                        data-src="/images/floorplan/sangam/sangam_sernity_1BHK_B.jpg"
                        className="img_overlay"
                      >
                        <Image
                          fill
                          src="/images/floorplan/sangam/sangam_sernity_1BHK_B.jpg"
                          alt="interior"
                          className="image"
                        />
                        <div className="gall_over"></div>
                      </div>
                    </div>
                    <div className="gall_detail text-center">
                      <p className="gal_txt gal_title mb-0">Sangam Serenity </p>
                      <p className="my-1 gal_conf">
                        <strong> 1 BHK Type - B</strong>
                      </p>
                      <p className="mb-0 gal_price">
                        567 Sq.ft{" "}
                        <i className="fa-solid fa-indian-rupee-sign"></i> Price
                        On Request
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img-wrap image-container gall_wrp">
                      <div
                        data-fancybox="sangam"
                        data-src="/images/floorplan/sangam/sangam_sernity_2BHK_EH.jpg"
                        className="img_overlay"
                      >
                        <Image
                          fill
                          src="/images/floorplan/sangam/sangam_sernity_2BHK_EH.jpg"
                          alt="interior"
                          className="image"
                        />
                        <div className="gall_over"></div>
                      </div>
                    </div>
                    <div className="gall_detail text-center">
                      <p className="gal_txt gal_title mb-0">Sangam Serenity </p>
                      <p className="my-1 gal_conf">
                        <strong> 2 BHK - EH</strong>
                      </p>
                      <p className="mb-0 gal_price">
                        887 Sq.ft{" "}
                        <i className="fa-solid fa-indian-rupee-sign"></i> Price
                        On Request
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img-wrap image-container gall_wrp">
                      <div
                        data-fancybox="sangam"
                        data-src="/images/floorplan/sangam/sangam_sernity_2BHK_FG.jpg"
                        className="img_overlay"
                      >
                        <Image
                          fill
                          src="/images/floorplan/sangam/sangam_sernity_2BHK_FG.jpg"
                          alt="interior"
                          className="image"
                        />
                        <div className="gall_over"></div>
                      </div>
                    </div>
                    <div className="gall_detail text-center">
                      <p className="gal_txt gal_title mb-0">Sangam Serenity </p>
                      <p className="my-1 gal_conf">
                        <strong> 2 BHK - FG</strong>
                      </p>
                      <p className="mb-0 gal_price">
                        887 Sq.ft{" "}
                        <i className="fa-solid fa-indian-rupee-sign"></i> Price
                        On Request
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img-wrap image-container gall_wrp">
                      <div
                        data-fancybox="sangam"
                        data-src="/images/floorplan/sangam/sangam_sernity_3BHK.jpg"
                        className="img_overlay"
                      >
                        <Image
                          fill
                          src="/images/floorplan/sangam/sangam_sernity_3BHK.jpg"
                          alt="interior"
                          className="image"
                        />
                        <div className="gall_over"></div>
                      </div>
                    </div>
                    <div className="gall_detail text-center">
                      <p className="gal_txt gal_title mb-0">Sangam Serenity </p>
                      <p className="my-1 gal_conf">
                        <strong> 3 BHK</strong>
                      </p>
                      <p className="mb-0 gal_price">
                        1039 Sq.ft{" "}
                        <i className="fa-solid fa-indian-rupee-sign"></i> Price
                        On Request
                      </p>
                    </div>
                  </SwiperSlide>
                  <div className="swiper-nav mt-4">
                    <button className="fa-solid fa-chevron-left cust-swiper-button-prev">
                      <span>prev</span>
                    </button>
                    <button className="fa-solid fa-chevron-right cust-swiper-button-next">
                      <span>next</span>
                    </button>
                  </div>
                </Swiper>
              </Tab>
              {/* <Tab eventKey="prayag" title="Prayag">
                <Swiper
                  modules={[Autoplay, Navigation]}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  navigation={{
                    nextEl: ".cust-swiper-button-next",
                    prevEl: ".cust-swiper-button-prev",
                  }}
                  loop={true}
                  speed={1200}
                  className="cust_slider"
                  breakpoints={{
                    767: {
                      slidesPerView: 1,
                      spaceBetween: 24,
                    },
                    992: {
                      slidesPerView: 2,
                      spaceBetween: 24,
                    },
                    1200: {
                      slidesPerView: 3,
                      spaceBetween: 24,
                    },
                  }}
                >
                  <SwiperSlide>
                    <div className="img-wrap image-container gall_wrp">
                      <div
                        data-fancybox="prayag"
                        data-src="/images/floorplan/prayag/Prayag1BHK_type_1.jpg"
                        className="img_overlay"
                      >
                        <Image
                          fill
                          src="/images/floorplan/prayag/Prayag1BHK_type_1.jpg"
                          alt="interior"
                          className="image"
                        />
                        <div className="gall_over"></div>
                      </div>
                    </div>
                    <div className="gall_detail text-center">
                      <p className="gal_txt gal_title mb-0">1 BHK</p>
                      <p className="mb-0 gal_price mt-1">
                        549 Sq.ft{" "}
                        <i className="fa-solid fa-indian-rupee-sign"></i> Sold
                        Out
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img-wrap image-container gall_wrp">
                      <div
                        data-fancybox="prayag"
                        data-src="/images/floorplan/prayag/Prayag2BHK_EH.jpg"
                        className="img_overlay"
                      >
                        <Image
                          fill
                          src="/images/floorplan/prayag/Prayag2BHK_EH.jpg"
                          alt="interior"
                          className="image"
                        />
                        <div className="gall_over"></div>
                      </div>
                    </div>
                    <div className="gall_detail text-center">
                      <p className="gal_txt gal_title mb-0">2 BHK</p>
                      <p className="mb-0 gal_price mt-1">
                        884 Sq.ft{" "}
                        <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                        37,50,00
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img-wrap image-container gall_wrp">
                      <div
                        data-fancybox="prayag"
                        data-src="/images/floorplan/prayag/Prayag3bhk.jpg"
                        className="img_overlay"
                      >
                        <Image
                          fill
                          src="/images/floorplan/prayag/Prayag3bhk.jpg"
                          alt="interior"
                          className="image"
                        />
                        <div className="gall_over"></div>
                      </div>
                    </div>
                    <div className="gall_detail text-center">
                      <p className="gal_txt gal_title mb-0">3 BHK</p>
                      <p className="mb-0 gal_price mt-1">
                        1040 Sq.ft{" "}
                        <i className="fa-solid fa-indian-rupee-sign"></i> Sold
                        Out
                      </p>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div className="mt-5 text-center">
                  <Link
                    className="btn"
                    href="/residential-flat#rate-calculator-box"
                  >
                    GET DETAILED QUOTE
                  </Link>
                </div>
              </Tab> */}
            </Tabs>
          </div>
        </Container>
      </section>
      <section className="press sec-padd">
        <Container>
          <Swiper
            modules={[Autoplay, Navigation]}
            // autoplay={{
            //   delay: 2500,
            //   disableOnInteraction: false,
            // }}
            navigation={{
              nextEl: ".custpres-swiper-button-next",
              prevEl: ".custpres-swiper-button-prev",
            }}
            loop={true}
            speed={1200}
            className="cust_slider"
            breakpoints={{
              767: {
                slidesPerView: 1,
                spaceBetween: 24,
              },
              992: {
                slidesPerView: 2,
                spaceBetween: 24,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 24,
              },
            }}
          >
            <SwiperSlide>
              <div className="img-wrap image-container gall_wrp">
                <div
                  data-fancybox="press"
                  data-src="/images/press/imes-real-estate-review-and-times-brand-icons-west-bengal-2024-celebrates.jpg"
                  className="img_overlay"
                >
                  <Image
                    fill
                    src="/images/press/imes-real-estate-review-and-times-brand-icons-west-bengal-2024-celebrates.jpg"
                    alt="interior"
                    className="image"
                  />
                  <div className="gall_over"></div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="img-wrap image-container gall_wrp">
                <div
                  data-fancybox="press"
                  data-src="/images/press/hind_atma.jpg"
                  className="img_overlay"
                >
                  <Image
                    fill
                    src="/images/press/hind_atma.jpg"
                    alt="interior"
                    className="image"
                  />
                  <div className="gall_over"></div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="img-wrap image-container gall_wrp">
                <div
                  data-fancybox="press"
                  data-src="/images/press/social_media.jpg"
                  className="img_overlay"
                >
                  <Image
                    fill
                    src="/images/press/social_media.jpg"
                    alt="interior"
                    className="image"
                  />
                  <div className="gall_over"></div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="img-wrap image-container gall_wrp">
                <div
                  data-fancybox="press"
                  data-src="/images/press/Prabhat_khabar.jpg"
                  className="img_overlay"
                >
                  <Image
                    fill
                    src="/images/press/Prabhat_khabar.jpg"
                    alt="interior"
                    className="image"
                  />
                  <div className="gall_over"></div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="swiper-nav mt-4">
            <button className="fa-solid fa-chevron-left custpres-swiper-button-prev">
              <span>prev</span>
            </button>
            <button className="fa-solid fa-chevron-right custpres-swiper-button-next">
              <span>next</span>
            </button>
          </div>
          <div className="mt-5 text-center">
            <Link className="btn" href="/gallery">
              PRESS UPDATE
            </Link>
          </div>
        </Container>
      </section>
      <section className="good-living-section">
        <Swiper
          modules={[Autoplay, Navigation]}
          // autoplay={{
          //   delay: 2500,
          //   disableOnInteraction: false,
          // }}
          navigation={{
            nextEl: ".cust-swiper-button-next",
            prevEl: ".cust-swiper-button-prev",
          }}
          loop={true}
          speed={1200}
          className="cust_slider good-living-slider"
          slidesPerView={1}
        >
          <SwiperSlide>
            <div className="img-wrap image-container video videothumb">
              <div
                data-fancybox="video"
                data-src="https://www.youtube.com/embed/zw7fty0-PcI"
                className="img_overlay"
              >
                <Image
                  fill
                  src="/images/new_kolkata_thumb.webp"
                  alt="video"
                  className="image"
                />
              </div>
            </div>
            {/* <iframe
              src="https://www.youtube.com/embed/zw7fty0-PcI"
              allowFullScreen
              title="video"
              height={700}
              width={1920}
              className="w-100"
            /> */}
          </SwiperSlide>
          <SwiperSlide>
            <div className="living-item">
              <div className="img-wrap image-container">
                <Image
                  fill
                  src="/images/gallery/club_house.jpg"
                  alt="interior"
                  className="image"
                />
              </div>
              <div className="living-detail">
                <div className="container">
                  <div className="center-title">
                    <h3 className="section-title">Club House</h3>
                  </div>
                  <p>
                    Need a place to relax after a long hard day's work? Our well
                    equipped club house is ready to give you the recreation you
                    need while overlooking the Ganges.
                  </p>
                  <Link
                    className="read-more btn"
                    href="/facilities-amenities?q=club_house"
                  >
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="living-item">
              <div className="img-wrap image-container">
                <Image
                  fill
                  src="/images/gallery/outdoor.jpg"
                  alt="interior"
                  className="image"
                />
              </div>
              <div className="living-detail">
                <div className="container">
                  <div className="center-title">
                    <h3 className="section-title">Outdoor</h3>
                  </div>
                  <p>
                    Built on the banks of the Ganges; New Kolkata comes with
                    amenities like an outdoor children's play area, landscaped
                    waterbody, jogging tracks, pet park and riverside promenade.
                    Spend quality time with your family at your green abode.
                  </p>
                  <Link
                    className="read-more btn"
                    href="/facilities-amenities?q=outdoor"
                  >
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="living-item">
              <div className="img-wrap image-container">
                <Image
                  fill
                  src="/images/gallery/neighborhood.jpg"
                  alt="interior"
                  className="image"
                />
              </div>
              <div className="living-detail">
                <div className="container">
                  <div className="center-title">
                    <h3 className="section-title">
                      Neighborhood Shopping Zone
                    </h3>
                  </div>
                  <p>
                    Neighborhood Shopping Zone: Indulge in your very own
                    neighborhood shopping zone right here at New Kolkata.
                    Convenience of shopping for your everyday requirements has
                    never been as easy as this!
                  </p>
                  <Link
                    className="read-more btn"
                    href="/facilities-amenities?q=neighborhood_shopping_zone"
                  >
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="living-item">
              <div className="img-wrap image-container">
                <Image
                  fill
                  src="/images/gallery/common_utilities.jpg"
                  alt="interior"
                  className="image"
                />
              </div>
              <div className="living-detail">
                <div className="container">
                  <div className="center-title">
                    <h3 className="section-title">
                      Common Utilities & Services
                    </h3>
                  </div>
                  <p>
                    The new city comes with its very own home theatre,
                    well-provisioned administration along with banquet hall,
                    diagnostic centre with doctor's chamber, pharmacy along with
                    other world-class amenities.
                  </p>
                  <Link
                    className="read-more btn"
                    href="/facilities-amenities?q=common_utilities_services"
                  >
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <div className="swiper-nav mt-4">
            <button className="fa-solid fa-chevron-left cust-swiper-button-prev">
              <span>prev</span>
            </button>
            <button className="fa-solid fa-chevron-right cust-swiper-button-next">
              <span>next</span>
            </button>
          </div>
        </Swiper>
      </section>
      <section className="gallery sec-padd">
        <Container>
          <div className="inner-text text-center">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <p>
                  Wake up every morning to the gentle breeze from the Ganges at
                  Alcove New Kolkata Sangam. This peaceful setting can be your
                  reality, bringing deep peace and happiness. Live right next to
                  the sacred river, where every moment shows how beautiful life
                  can be. Enjoy top-quality living and see for yourself the
                  magic of this amazing lifestyle without spending a fortune.
                </p>
              </div>
            </div>

            <Link className="btn" href="/contact-us">
              Plan A Visit
            </Link>
          </div>
          {constructionUpdate.length !== 0 &&
          constructionUpdate[0]?.attributes?.featuredOnHome == true ? (
            <div className="gallery-sec sec-padd-top">
              <div className="head_wrap">
                <div
                  className="center-title text-center"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                >
                  <h3 className="section-title">CONSTRUCTION UPDATE</h3>
                </div>
              </div>
              <div className="mt-30">
                <Swiper
                  modules={[Autoplay, Navigation]}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  navigation={{
                    nextEl: ".cust-swiper-button-next",
                    prevEl: ".cust-swiper-button-prev",
                  }}
                  loop={true}
                  speed={1200}
                  className="cust_slider"
                  breakpoints={{
                    767: {
                      slidesPerView: 1,
                      spaceBetween: 24,
                    },
                    992: {
                      slidesPerView: 2,
                      spaceBetween: 24,
                    },
                    1200: {
                      slidesPerView: 3,
                      spaceBetween: 24,
                    },
                  }}
                >
                  {constructionImages &&
                    constructionImages.map((constImage, i) => {
                      // console.log('constImage',constImage)
                      return (
                        <SwiperSlide key={`constImage` + i}>
                          <div className="img-wrap image-container gall_wrp">
                            <div
                              data-fancybox={`constImage${constructionID}`}
                              data-src={cleanImage(
                                constImage?.Image?.data?.attributes?.url
                              )}
                              data-caption={constImage?.Caption}
                              className="img_overlay"
                            >
                              <Image
                                fill
                                src={cleanImage(
                                  constImage?.Image?.data?.attributes?.url
                                )}
                                alt={
                                  constImage?.Image?.data?.attributes
                                    ?.alternativeText
                                    ? constImage?.Image?.data?.attributes
                                        ?.alternativeText
                                    : "construction image"
                                }
                                className="image"
                              />
                              <div className="gall_over"></div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}

                  {/* <SwiperSlide>
                  <div className="img-wrap image-container gall_wrp">
                    <div
                      data-fancybox="construction"
                      data-src="/images/construction_update/1_nov_2023/Sangam-Tower-2-3rd-Floor-Roof-Casting-Work-in-Progress.jpg"
                      data-caption="Work in Progress on Podium 2 Roon Casting"
                      className="img_overlay"
                    >
                      <Image
                        fill
                        src="/images/construction_update/1_nov_2023/Sangam-Tower-2-3rd-Floor-Roof-Casting-Work-in-Progress.jpg"
                        alt="interior"
                        className="image"
                      />
                      <div className="gall_over"></div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-wrap image-container gall_wrp">
                    <div
                      data-fancybox="construction"
                      data-src="/images/construction_update/1_nov_2023/Sangam-Tower-3-Podium-2-Roof-Casting-Work-in-Progress.jpg"
                      data-caption="Work in Progress on Ground Floor "
                      className="img_overlay"
                    >
                      <Image
                        fill
                        src="/images/construction_update/1_nov_2023/Sangam-Tower-3-Podium-2-Roof-Casting-Work-in-Progress.jpg"
                        alt="interior"
                        className="image"
                      />
                      <div className="gall_over"></div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-wrap image-container gall_wrp">
                    <div
                      data-fancybox="construction"
                      data-src="/images/construction_update/1_nov_2023/Sangam-Tower-5-Completion-of-27th-Floor-Roof-Casting.jpg"
                      data-caption="Work in Progress on 22nd Floor Roon Casting "
                      className="img_overlay"
                    >
                      <Image
                        fill
                        src="/images/construction_update/1_nov_2023/Sangam-Tower-5-Completion-of-27th-Floor-Roof-Casting.jpg"
                        alt="interior"
                        className="image"
                      />
                      <div className="gall_over"></div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-wrap image-container gall_wrp">
                    <div
                      data-fancybox="construction"
                      data-src="/images/construction_update/1_nov_2023/Sangam-Tower-6-Completion-of-27th-Floor-Roof-Casting.jpg"
                      data-caption="Completion on 27th Floor Roon Casting"
                      className="img_overlay"
                    >
                      <Image
                        fill
                        src="/images/construction_update/1_nov_2023/Sangam-Tower-6-Completion-of-27th-Floor-Roof-Casting.jpg"
                        alt="interior"
                        className="image"
                      />
                      <div className="gall_over"></div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-wrap image-container gall_wrp">
                    <div
                      data-fancybox="construction"
                      data-src="/images/construction_update/1_nov_2023/Sangam-Tower-7-Completion-of-27th-Floor-Roof-Casting.jpg"
                      data-caption="Work in Progress on 15th Floor Roon Casting "
                      className="img_overlay"
                    >
                      <Image
                        fill
                        src="/images/construction_update/1_nov_2023/Sangam-Tower-7-Completion-of-27th-Floor-Roof-Casting.jpg"
                        alt="interior"
                        className="image"
                      />
                      <div className="gall_over"></div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-wrap image-container gall_wrp">
                    <div
                      data-fancybox="construction"
                      data-src="/images/construction_update/1_nov_2023/Sangam-Tower-9-15th-Floor-Roof-Casting-Work-in-Progress.jpg"
                      data-caption="Work in Progress on Podium 2 Roon Casting"
                      className="img_overlay"
                    >
                      <Image
                        fill
                        src="/images/construction_update/1_nov_2023/Sangam-Tower-9-15th-Floor-Roof-Casting-Work-in-Progress.jpg"
                        alt="interior"
                        className="image"
                      />
                      <div className="gall_over"></div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-wrap image-container gall_wrp">
                    <div
                      data-fancybox="construction"
                      data-src="/images/construction_update/1_nov_2023/Sangam-Tower-10-Ground-Floor-Work-in-Progress.jpg"
                      data-caption="Work in Progress on Ground Floor"
                      className="img_overlay"
                    >
                      <Image
                        fill
                        src="/images/construction_update/1_nov_2023/Sangam-Tower-10-Ground-Floor-Work-in-Progress.jpg"
                        alt="interior"
                        className="image"
                      />
                      <div className="gall_over"></div>
                    </div>
                  </div>
                </SwiperSlide> */}
                  <div className="swiper-nav mt-4">
                    <button className="fa-solid fa-chevron-left cust-swiper-button-prev">
                      <span>prev</span>
                    </button>
                    <button className="fa-solid fa-chevron-right cust-swiper-button-next">
                      <span>next</span>
                    </button>
                  </div>
                </Swiper>
                <div className="text-center mt-4">
                  <Link className="btn" href="/gallery-detail">
                    View More Updates
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </Container>
      </section>
      <Testimonial />
      <Partners />
    </>
  );
}

/** For server side rendering */
export const getServerSideProps = wrapper.getServerSideProps(
  (store) =>
    async ({ req, query }) => {
      try {
        const query = {
          populate: [
            "*",
            "seo",
            "seo.metaImage",
            "seo.schema",
            "Banner.DesktopBanner",
            "Banner.MobileBanner",
          ],
        };

        /** If we are loading projects from anywhere other than the home page, then we load the configuration information also. */

        const queryString = qs.stringify(query, {
          encodeValuesOnly: true,
        });

        const endpoint = `${process.env.NEXT_PUBLIC_BACKEND_API_URL}/api/home?${queryString}`;
        // console.log(`Final url: ${endpoint}`);

        const response = await axios.get(endpoint);
        const data = response.data.data;

        await store.dispatch(getConstructionUpdate());

        return {
          props: { data },
        };
      } catch (error) {
        console.log("peoject", error);
      }
    }
);
