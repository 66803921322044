import React from "react";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import { Container } from "react-bootstrap";
import Image from "next/image";
const testimonials = [
  {
    id: 1,
    title: "Voices of Delight: Resonating Customer Testimonials from Alcove New Kolkata Sangam #AlcoveRealty",
    image: "/images/gallery/testimonial/test1.jpg",
    videolink: "https://www.youtube.com/embed/sLqZygdFZyA",
  },
  {
    id: 2,
    title:
      "Voices of Delight: Resonating Customer Testimonials from Alcove New Kolkata Sangam #AlcoveRealty",
    image: "/images/gallery/testimonial/test2.jpg",
    videolink: "https://www.youtube.com/embed/pv9P0b3anvw?si=13VJy5w5SuJKFWvp",
  },
  {
    id: 3,
    title:
      "Voices of Delight: Resonating Customer Testimonials from Alcove New Kolkata Sangam #AlcoveRealty",
    image: "/images/gallery/testimonial/test3.jpg",
    videolink: "https://www.youtube.com/embed/jUdy--_Ohb4?si=PgRDuOgD4qPGLJjI",
  },
  {
    id: 4,
    title:
      "Voices of Delight: Resonating Customer Testimonials from Alcove New Kolkata Sangam #AlcoveRealty",
    image: "/images/gallery/testimonial/test4.jpg",
    videolink: "https://www.youtube.com/embed/Fpy4UamIUbE?si=HpdH_ROzsHAlc5qb",
  },
];
const Testimonial = () => {
  return (
    <>
      <section className="gallery video">
        <Container>
          <div
            className="head_wrap"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
          >
            <div className="center-title text-center">
              <h3 className="section-title">TESTIMONIALS</h3>
            </div>
          </div>
          <div className="mt-30">
            <Swiper
              modules={[Autoplay, Navigation]}
              // autoplay={{
              //   delay: 2500,
              //   disableOnInteraction: false,
              // }}
              navigation={{
                nextEl: ".cust-swiper-button-next",
                prevEl: ".cust-swiper-button-prev",
              }}
              loop={true}
              speed={1200}
              className="cust_slider"
              breakpoints={{
                767: {
                  slidesPerView: 1,
                  spaceBetween: 24,
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 24,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 24,
                },
              }}
            >
              {testimonials &&
                testimonials.map((item) => {
                  return (
                    <SwiperSlide key={item.id}>
                      <div
                        className="img-wrap image-container gall_wrp"
                        key={item.id}
                      >
                        <div
                          data-fancybox="testimonial"
                          data-src={item.videolink}
                          className="img_overlay"
                        >
                          <Image
                            fill
                            src={item.image}
                            alt="interior"
                            className="image"
                          />
                        </div>
                      </div>
                      <p className="mt-2 text-center mb-0">{item.title}</p>
                    </SwiperSlide>
                  );
                })}

              <div className="swiper-nav mt-4">
                <button className="fa-solid fa-chevron-left cust-swiper-button-prev">
                  <span>prev</span>
                </button>
                <button className="fa-solid fa-chevron-right cust-swiper-button-next">
                  <span>next</span>
                </button>
              </div>
            </Swiper>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Testimonial;
