import Image from "next/image";
import React from "react";
import { Container } from "react-bootstrap";
import axis_logo from "../public/images/partners_logo/axis_logo.png";
import dhfl_logo from "../public/images/partners_logo/dhfl_logo.png";
import iffl_logo from "../public/images/partners_logo/iffl_logo.png";
import indiabill_logo from "../public/images/partners_logo/indiabill_logo.png";
import sbibank_logo from "../public/images/partners_logo/sbibank_logo.png";
import ABHFL_logo from "../public/images/partners_logo/ABHFL_logo.jpg";
import bajaj_finance from "../public/images/partners_logo/bajaj_finance.png";
import hdfc_logo from "../public/images/partners_logo/hdfc_logo.png";
import home_loans_Logo from "../public/images/partners_logo/home_loans_Logo.jpg";
import icici_logo from "../public/images/partners_logo/icici_logo.jpg";
import ucobank from "../public/images/partners_logo/uco-bank.jpg";
import pnbhousing from "../public/images/partners_logo/pnb-housing.jpg";
import lic from "../public/images/partners_logo/lic.jpg";
import canara from "../public/images/partners_logo/canara.png";
import Syndicate_Bank from "../public/images/partners_logo/Syndicate_Bank.png";
import reliance from "../public/images/partners_logo/reliance.png";
import union_logo from "../public/images/partners_logo/union_logo.png";
import BOI from "../public/images/partners_logo/central-bank-of-india.jpg";
import icici_bank from "../public/images/partners_logo/icici_bank.png";

const Partners = () => {
  return (
    <>
      <section className="partner-section sec-padd">
        <Container fluid={true}>
          <div className="head_wrap text-center">
            <div className="center-title">
              <h2
                className="section-title"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="1000"
              >
                <span>Our Finance Partners</span>
              </h2>
            </div>
          </div>
          <div className="partner-logo-wrapper">
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                {" "}
                <Image alt="partner" className="partner-logo" src={axis_logo} />
              </figure>
            </div>
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                {" "}
                <Image alt="partner" className="partner-logo" src={dhfl_logo} />
              </figure>
            </div>
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                {" "}
                <Image alt="partner" className="partner-logo" src={iffl_logo} />
              </figure>
            </div>
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                {" "}
                <Image
                  alt="partner"
                  className="partner-logo"
                  src={indiabill_logo}
                />
              </figure>
            </div>
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                <Image
                  alt="partner"
                  className="partner-logo"
                  src={sbibank_logo}
                />
              </figure>
            </div>
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                <Image
                  alt="partner"
                  className="partner-logo"
                  src={ABHFL_logo}
                />
              </figure>
            </div>
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                <Image
                  alt="partner"
                  className="partner-logo"
                  src={bajaj_finance}
                />
              </figure>
            </div>
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                <Image alt="partner" className="partner-logo" src={hdfc_logo} />
              </figure>
            </div>
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                <Image
                  alt="partner"
                  className="partner-logo"
                  src={home_loans_Logo}
                />
              </figure>
            </div>
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                <Image
                  alt="partner"
                  className="partner-logo"
                  src={icici_logo}
                />
              </figure>
            </div>
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                <Image alt="partner" className="partner-logo" src={ucobank} />
              </figure>
            </div>
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                <Image
                  alt="partner"
                  className="partner-logo"
                  src={pnbhousing}
                />
              </figure>
            </div>
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                <Image
                  alt="partner"
                  className="partner-logo"
                  src={icici_bank}
                />
              </figure>
            </div>
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                <Image alt="partner" className="partner-logo" src={canara} />
              </figure>
            </div>
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                <Image
                  alt="partner"
                  className="partner-logo"
                  src={Syndicate_Bank}
                />
              </figure>
            </div>
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                <Image alt="partner" className="partner-logo" src={reliance} />
              </figure>
            </div>
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                <Image
                  alt="partner"
                  className="partner-logo"
                  src={union_logo}
                />
              </figure>
            </div>
            <div
              className="logo-box"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="1000"
            >
              <figure>
                <Image alt="partner" className="partner-logo" src={BOI} />
              </figure>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Partners;
